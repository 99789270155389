exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-games-mecha-index-js": () => import("./../../../src/pages/games/mecha/index.js" /* webpackChunkName: "component---src-pages-games-mecha-index-js" */),
  "component---src-pages-parallax-example-js": () => import("./../../../src/pages/parallax-example.js" /* webpackChunkName: "component---src-pages-parallax-example-js" */),
  "component---src-pages-portafolio-index-js": () => import("./../../../src/pages/portafolio/index.js" /* webpackChunkName: "component---src-pages-portafolio-index-js" */),
  "component---src-pages-resume-index-js": () => import("./../../../src/pages/resume/index.js" /* webpackChunkName: "component---src-pages-resume-index-js" */),
  "component---src-pages-send-form-index-js": () => import("./../../../src/pages/send-form/index.js" /* webpackChunkName: "component---src-pages-send-form-index-js" */),
  "component---src-templates-home-home-js": () => import("./../../../src/templates/home/Home.js" /* webpackChunkName: "component---src-templates-home-home-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/page/Page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-post-post-js": () => import("./../../../src/templates/post/Post.js" /* webpackChunkName: "component---src-templates-post-post-js" */)
}

